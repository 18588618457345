const { createTheme } = require("@mui/material");

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#eee",
      paper: "#FAF9F6",
    },
    primary: {
      main: "#123",
    },
    info: {
      main: "#234",
    },
  },
});
