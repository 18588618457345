const { createTheme } = require("@mui/material");

export const darkTheme = createTheme({
  palette: {
    mode: "dark",

    background: {
      default: "#111",
      paper: "#000",
    },
  },
});
