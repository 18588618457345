import React, { useCallback, useEffect, useState } from "react";
import withAuth from "../hoc/withAuth";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import { AddPhotoAlternate, Edit, PhotoAlbum } from "@mui/icons-material";
import { useFetchData } from "../store/hooks/useFetch";
import { toApiUrl } from "../utils/generic";
import { addNotification } from "../store/actions/notifications";
import axios from "../apiClient";
import TabContainer from "../components/tabs/TabContainer";
import useForm from "../store/hooks/useForm";
import ValidationService from "easy-validation-service";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import Title from "../components/ui/Title";
import Input from "../components/forms/Input";
import ImageTabContainer from "../components/images/ImageTabContainer";

function ImagesPage() {
  const formConfig = useForm({
    id: {
      defaultValue: "",
    },
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          value: value?.trim(),
          min: 2,
          max: 50,
        }),
    },
  });

  const [entity, setEntity] = useState(null);

  const {
    data: categories,
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useFetchData(toApiUrl("/categories"));
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AnalyticsCard title="Gallery" icon={<PhotoAlbum />}>
          <IconButton
            onClick={() => {
              setEntity({});
              formConfig.resetForm({ name: "", id: "" });
            }}
          >
            <Tooltip title="New Category">
              <AddPhotoAlternate />
            </Tooltip>
          </IconButton>
        </AnalyticsCard>
      </Grid>
      {categories?.length ? (
        <Grid item md={12} xs={12}>
          <TabContainer
            sx={{ p: 2, mb: 2 }}
            currentTab
            onTabChange={(_, value) => console.log(value)}
            tabs={(categories || []).map((item) => ({
              label: item.name,
              id: item.id,
              name: item.name,
              icon: <PhotoAlbum />,
            }))}
          >
            {(categories || []).map((category) => (
              <ImageTabContainer
                category={category}
                key={category.id}
                onEdit={(category) => {
                  setEntity(category);
                  formConfig.resetForm({
                    name: category?.name,
                    id: category.id,
                  });
                }}
                onDeleteCategory={refetchCategories}
              />
            ))}
          </TabContainer>
        </Grid>
      ) : null}
      <SimpleDialog
        open={!!entity}
        title={
          <Title>
            <Edit sx={{ mr: 2 }} /> {formConfig.form.id.value ? "Edit" : "Add"}{" "}
            Category
          </Title>
        }
        disableBackdropClick={isLoading || categoriesLoading}
        disableConfirm={
          !formConfig.isValid ||
          !formConfig.isTouched ||
          isLoading ||
          categoriesLoading
        }
        onCancel={() => {
          setEntity(null);
          formConfig.resetForm({ name: "", id: "" });
        }}
        onConfirm={async () => {
          setIsLoading(true);
          try {
            const { id, name } = formConfig.getFormValues();
            if (!id) {
              await axios.post(toApiUrl("/categories"), { name });
            } else {
              await axios.put(toApiUrl(`/categories/${id}`), { name });
            }
            addNotification("Action completed", "success");
            refetchCategories();
            setEntity(null);
            formConfig.resetForm({ name: "", id: "" });
          } catch (error) {
            addNotification("Action failed", "error");
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Input
          {...formConfig.form.name}
          name="name"
          label="Category"
          value={formConfig.form.name.value}
          onChange={formConfig.handleChange}
        />
      </SimpleDialog>
    </Grid>
  );
}

export default withAuth(ImagesPage);
