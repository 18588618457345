import ValidationService from "easy-validation-service";
import useForm from "../../store/hooks/useForm";

export default function useProfileForm(user) {
  return useForm({
    id: {
      defaultValue: user?.id,
      validators: (value) => !ValidationService.isNullOrUndefinedOrEmpty(value),
    },
    email: {
      defaultValue: user?.email,
      validators: (value) => ValidationService.validateEmail(value),
    },
    name: {
      defaultValue: user?.name,
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
    last_name: {
      defaultValue: user?.last_name,
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
  });
}
