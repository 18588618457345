import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dataReducer } from "./dataReducer";
import { themeReducer } from "./themeReducer";

export const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  data: dataReducer,
});
