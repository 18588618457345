import { Box, Button } from "@mui/material";
import React from "react";
import Modal from "./Modal";

export default function SimpleDialog({
  onCancel,
  onConfirm,
  disableConfirm,
  open,
  title,
  children,
  disableBackdropClick,
  ...rest
}) {
  if (!open) {
    return null;
  }

  const renderActions = () => {
    return (
      <>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        {onConfirm && (
          <Button
            variant="contained"
            disabled={disableConfirm}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        )}
      </>
    );
  };
  return (
    <Modal
      title={title}
      open={open}
      renderActions={renderActions}
      disableBackdropClick={disableBackdropClick}
      onClose={onCancel}
      maxWidth="md"
      {...rest}
    >
      <Box sx={{ mt: 1 }}>{children}</Box>
    </Modal>
  );
}
