import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";

import { toServerImage } from "../../utils/generic";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function ImageItem({ image, onDeleteFile }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
              src={"/logo.png"}
            />
          }
          title={image.original_name}
          subheader={new Date(image.created_at)?.toLocaleDateString()}
        />
        <CardMedia
          component="img"
          style={{ objectFit: "contain", maxWidth: "100%", aspectRatio: "2/1" }}
          image={toServerImage(image.path)}
          alt={image.original_name}
        />
        <CardContent></CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="delete" onClick={() => setOpen(true)}>
            <Delete />
          </IconButton>
        </CardActions>
      </Card>
      <ConfirmationDialog
        onCancel={() => setOpen(false)}
        onConfirm={async () => await onDeleteFile(image)}
        open={open}
      />
    </>
  );
}
