import React from "react";
import withAuth from "../hoc/withAuth";
import { useFetchData } from "../store/hooks/useFetch";
import { toApiUrl } from "../utils/generic";
import BackdropLoader from "../components/ui/BackdropLoader";
import { Avatar, Button, CircularProgress, Grid } from "@mui/material";
import {
  Message,
  Person2Rounded,
  PhotoAlbum,
  Settings,
} from "@mui/icons-material";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import { useNavigate } from "react-router-dom";
import ProfileForm from "../components/profile/ProfileForm";
import Title from "../components/ui/Title";
import ChangePasswordForm from "../components/profile/ChangePasswordForm";

function DashboardPage({ user }) {
  const { data, loading } = useFetchData(toApiUrl("/aggregate"));
  const navigate = useNavigate();
  if (loading) {
    return <BackdropLoader open={loading} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Title divider sx={{ mb: 2 }}>
          <Avatar src="/logo.png" sx={{ mr: 2 }} />
          {`${user.name} ${user.last_name}`}
        </Title>
      </Grid>
      {[
        { name: "images", title: "Gallery", icon: <PhotoAlbum /> },
        { name: "messages", title: "Messages", icon: <Message /> },
      ].map((property) => (
        <Grid item md={6} xs={12} key={property}>
          <AnalyticsCard
            {...property}
            count={
              loading ? (
                <CircularProgress size={20} />
              ) : (
                data?.[property.name] || 0
              )
            }
          >
            <Button
              size="sm"
              variant="outlined"
              onClick={() => navigate(`/${property.name}`)}
              startIcon={<Settings />}
              style={{ textTransform: "none" }}
            >
              Manage
            </Button>
          </AnalyticsCard>
        </Grid>
      ))}
      <Grid item md={6} xs={12}>
        <ProfileForm />
      </Grid>
      <Grid item md={6} xs={12}>
        <ChangePasswordForm />
      </Grid>
    </Grid>
  );
}

export default withAuth(DashboardPage);
