import { TablePagination } from "@mui/material";
import React from "react";

export default function Paginator({
  total,
  page,
  view = 10,
  allowedViews = [5, 10, 20, 40],
  onPageChange,
  onViewChange,
}) {
  return (
    <TablePagination
      sx={{ float: "unset" }}
      rowsPerPageOptions={allowedViews}
      component="div"
      count={total || -1}
      rowsPerPage={view}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={(e) => onViewChange(e.target.value)}
    />
  );
}
