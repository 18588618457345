import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthWrapper from "./layouts/AuthWrapper";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import DashboardPage from "./pages/DashboardPage";
import ImagesPage from "./pages/ImagesPage";
import MessagesPage from "./pages/MessagesPage";

export default function Router() {
  return (
    <Routes>
      <Route index path="/" element={<DashboardPage />} />
      <Route path="/images" element={<ImagesPage />} />
      <Route path="/messages" element={<MessagesPage />} />

      <Route
        path="/login"
        element={
          <AuthWrapper>
            <LoginPage />
          </AuthWrapper>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
