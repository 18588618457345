import { Grid } from "@mui/material";
import React, { useState } from "react";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import { CalendarMonth, Mail, Message, Person } from "@mui/icons-material";
import TableDataLoader from "../components/dataGrid/TableDataLoader";
import { toApiUrl } from "../utils/generic";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import useTheme from "../store/hooks/useTheme";
import Title from "../components/ui/Title";
import withAuth from "../hoc/withAuth";

const MessageColumns = [
  {
    name: "id",
    label: "Message ID",
  },
  {
    name: "name",
    label: "User",
    renderComponent: ({ row }) => `${row.name} ${row.last_name}`,
  },
  { name: "email", label: "Email" },
  {
    name: "created_at",
    label: "Created",
    renderComponent: ({ value }) => new Date(value).toLocaleString("el-GR"),
  },
];

function MessagesPage() {
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AnalyticsCard title="Messages" icon={<Message />}></AnalyticsCard>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <TableDataLoader
          identifier="messages"
          entityName="results"
          endpoint={toApiUrl("/messages")}
          columns={MessageColumns}
          onRowClick={(row) => {
            setSelectedRow(row);
          }}
        />
      </Grid>
      <SimpleDialog open={!!selectedRow} onCancel={() => setSelectedRow(null)}>
        <Grid container spacing={2}>
          <Grid item>
            <Title>
              <Person sx={{ mr: 2 }} /> {selectedRow?.name}{" "}
              {selectedRow?.last_name}
            </Title>
          </Grid>
          <Grid item>
            <Title>
              <Mail sx={{ mr: 2 }} /> {selectedRow?.email}
            </Title>
          </Grid>
          <Grid item>
            <Title>
              <CalendarMonth />
              {new Date(selectedRow?.created_at)?.toLocaleString("el-GR")}
            </Title>
          </Grid>
          <Grid item md={12} xs={12}>
            <p
              style={{
                padding: "40px",
                background: theme?.themeMode === "dark" ? "#444" : "#eee",
              }}
            >
              {selectedRow?.message}
            </p>
          </Grid>
        </Grid>
      </SimpleDialog>
    </Grid>
  );
}
export default withAuth(MessagesPage);
