import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchData,
  initializeDataSlice,
  removeDataSlice,
  setSearchFilters,
} from "../../store/actions/data";
import RestTable from "../tables/rest/RestTable";
import Message from "../ui/Message";
import Filters from "./Filters";

const withDataLoader = (Component) => {
  return connect((state) => ({ dataReducer: state.data }), {
    initializeDataSlice,
    fetchData,
    removeDataSlice,
  })(
    ({
      dataReducer,
      identifier,
      initializeDataSlice,
      columns,
      method = "GET",
      endpoint,
      pageKey = "page",
      viewKey = "view",
      totalKey = "total",
      entityName,
      fetchData,
      filters = {},
      withFilters = false,
      filtersConfig = {},
      removeDataSlice,
      ...rest
    }) => {
      useEffect(() => {
        initializeDataSlice({
          identifier,
          payload: {
            method,
            endpoint,
            columns,
            pageKey,
            viewKey,
            totalKey,
            entityName,
            filters,
            filtersConfig,
            withFilters,
          },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
          removeDataSlice({ identifier });
        };
      }, []);
      const { isInitialized } = dataReducer?.[identifier] || {};
      if (!isInitialized) {
        return null;
      }
      return (
        <Component
          {...rest}
          {...dataReducer[identifier]}
          getData={fetchData}
          identifier={identifier}
          filtersConfig={filtersConfig}
        />
      );
    }
  );
};

function TableDataLoader({
  data,
  page,
  view,
  total,
  loading,
  columns,
  columnProps,
  renderToolbarChildren,
  getData,
  identifier,
  filters,
  filtersConfig,
  withFilters = false,
  onRowClick,
  ...rest
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    getData({ identifier, page, view, filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {withFilters ? (
        <Grid item md={12} xs={12} display="flex" justifyContent="flex-end">
          <Filters identifier={identifier} config={filtersConfig} />
        </Grid>
      ) : null}
      <Grid item md={12} xs={12}>
        <RestTable
          rows={data}
          page={page}
          view={view}
          count={total}
          isLoading={loading}
          columns={columns}
          allowedViews={[5, 10, 25, 50, 100]}
          onRowClick={onRowClick}
          columnProps={{
            ...columnProps,
            actions: {
              actions: rest.actions,
              getData: async () =>
                await getData({ page, view, filters, identifier }),
            },
          }}
          renderToolbarChildren={renderToolbarChildren}
          onPageChange={async (page) => {
            await getData({ identifier, page, view, filters });
          }}
          onViewChange={async (view) => {
            await getData({ identifier, page, view, filters });
          }}
          onSortChange={async (newOrder) => {
            if (!newOrder) {
              dispatch(
                setSearchFilters({
                  identifier,
                  filters: { ...rest.searchFilters, order_by: undefined },
                })
              );
            } else {
              dispatch(
                setSearchFilters({
                  identifier,
                  filters: {
                    ...rest.searchFilters,
                    order_by: Object.entries(newOrder)
                      .map(([key, value]) => {
                        return `${key}:${value}`;
                      })
                      .join(","),
                  },
                })
              );
            }
            await getData({ identifier, page, view, filters });
          }}
          defaultOrderBy={rest.defaultOrderBy}
          defaultSortingColumn={rest.defaultSortingColumn}
          disableSelection
        />
        {!loading && !data?.length ? (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Message message="No data at the moment" />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default withDataLoader(TableDataLoader);
