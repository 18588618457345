export const Config = {
  WS_URL:
    process.env.NODE_ENV === "development"
      ? "ws://localhost:8000/"
      : "https://empyrean-tattoo.gr/",
  API_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api"
      : "https://empyrean-tattoo.gr/api",
  STATIC_FILES_UPLOAD_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/images"
      : "https://empyrean-tattoo.gr/api/images",
  STATIC_FILES_SERVER_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/assets/uploads"
      : "https://empyrean-tattoo.gr/assets/uploads",
};
