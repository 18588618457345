import React, { useCallback, useState } from "react";
import ValidationService from "easy-validation-service";
import { Grid } from "@mui/material";
import SelectInput from "../forms/SelectInput";
import CustomSwitch from "../ui/Switch";
import Input from "../forms/Input";
import { clearSearchFilters, setSearchFilters } from "../../store/actions/data";
import { useDispatch } from "react-redux";
import useDataSlice from "./useDataSlice";
import CustomButton from "@mui/material/Button";
import SimpleDialog from "../dialogs/SimpleDialog";
import { FilterList } from "@mui/icons-material";
import { debounce } from "lodash";

export default function Filters({
  config,
  onChange,
  onApply,
  onClear,
  identifier,
}) {
  const [dataSlice, getData, setLoading] = useDataSlice(identifier);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { searchFilters } = dataSlice || {};

  const renderInput = useCallback(
    (input) => {
      switch (input.type) {
        case "select":
          return (
            <SelectInput
              sx={{ width: "100%" }}
              fullWidth
              onChange={(e) => {
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter
                    ? input.formatter(e.target.value)
                    : e.target.value,
                  ...(input.onChange &&
                    input.onChange(e.target.value, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }}
              label={input.label || input.name}
              name={input.name}
              options={input.options || []}
              value={searchFilters?.[input.name]}
              multiple={!!input.multiple}
              isValid
              isTouched
            />
          );
        case "checkbox":
          return (
            <CustomSwitch
              name={input.name}
              label={input.label || input.name}
              checked={searchFilters?.[input.name]}
              onChange={(_, val) => {
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter ? input.formatter(val) : val,
                  ...(input.onChange &&
                    input.onChange(val, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }}
            />
          );
        default:
          return (
            <Input
              onChange={debounce((e) => {
                e.persist();
                const newState = {
                  ...searchFilters,
                  [input.name]: input.formatter
                    ? input.formatter(e.target.value)
                    : e.target.value,
                  ...(input.onChange &&
                    input.onChange(e.target.value, input, searchFilters)),
                };
                if (onChange) {
                  onChange(newState, input);
                }
                dispatch(setSearchFilters({ identifier, filters: newState }));
                return newState;
              }, 200)}
              name={input.name}
              type={input.type}
              defaultValue={searchFilters?.[input.name]}
              label={input.label || input.name}
              isTouched
              isValid
            />
          );
      }
    },
    [searchFilters, onChange, dispatch, identifier]
  );
  if (!ValidationService.isNotEmptyArray(config)) {
    return null;
  }

  return (
    <>
      <CustomButton
        onClick={() => setOpen(true)}
        startIcon={<FilterList />}
        variant="outlined"
      >
        Filters
      </CustomButton>
      <SimpleDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
          setOpen(false);
        }}
      >
        <Grid container spacing={2}>
          {config.map((input) => (
            <Grid item md={12} xs={12} {...input.grid} key={input.name}>
              {renderInput(input)}
            </Grid>
          ))}

          <Grid item md={12} xs={12}>
            <CustomButton
              variant="outlined"
              onClick={async () => {
                dispatch(clearSearchFilters({ identifier }));
                await getData(
                  dataSlice?.page,
                  dataSlice?.view,
                  dataSlice?.filters
                );
                if (onClear) {
                  onClear();
                }
                setOpen(false);
              }}
            >
              Clear
            </CustomButton>
          </Grid>
        </Grid>
      </SimpleDialog>
    </>
  );
}
