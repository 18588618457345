/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectInput({
  name,
  onChange,
  value,
  label,
  startIcon,
  endIcon,
  type,
  isValid,
  isTouched = true,
  text,
  error,
  sx,
  disabled,
  id,
  className,
  style,
  multiple,
  renderValue,
  options,
  fullWidth = true,
  placeholder,
  withChip,
  withCheckBox,
  onDelete,
  placeholderDisabled,
  placeholderValue,
  ...rest
}) {
  const renderHelperText = () => {
    if (!isValid && isTouched) return error || "";
    if (text) return text;
  };

  const getDisplayValue = React.useMemo(() => {
    if (renderValue) return (selected) => renderValue(selected);

    if (withChip) {
      return (selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((value, idx) => (
            <Chip
              key={idx}
              label={options.find((opt) => opt.value === value)?.label}
              onDelete={(e) => {
                e.stopPropagation();
                if (onDelete) {
                  onDelete(selected.filter((item) => item !== value));
                }
              }}
            />
          ))}
        </Box>
      );
    }

    if (multiple) {
      return (selected) =>
        options
          .filter((opt) => selected?.some((val) => val === opt.value))
          .map(({ label }) => label)
          .join(", ");
    }

    return () => options.find((opt) => opt.value === value)?.label || "";
  }, [multiple, onDelete, options, renderValue, value, withChip]);

  const renderPlaceholder = () => {
    if (!placeholder) {
      return null;
    }

    return (
      <MenuItem disabled={placeholderDisabled} value={placeholderValue || ""}>
        <em>{placeholder ?? "-"}</em>
      </MenuItem>
    );
  };

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      <InputLabel>
        {startIcon} {label} {endIcon}
      </InputLabel>
      <Select
        multiple={multiple}
        id={id}
        MenuProps={MenuProps}
        className={className}
        style={style}
        disabled={disabled}
        value={value}
        name={name}
        type={type}
        error={isTouched && !isValid}
        onChange={onChange}
        input={<OutlinedInput label={label} fullWidth />}
        // {...(typeof renderValue !== 'undefined' && { renderValue: (selected) => renderValue(selected) })}
        renderValue={getDisplayValue}
        helperText={renderHelperText()}
        {...rest}
      >
        {renderPlaceholder()}
        {options.map(({ label, value }, idx) => (
          <MenuItem key={idx} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
