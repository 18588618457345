import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticate, logout } from "../store/actions/auth";
import useAuth from "../store/hooks/useAuth";
import BackdropLoader from "../components/ui/BackdropLoader";
import { refreshAccessToken } from "../apiClient";

function withAuth(Component, type = "HARD") {
  const WrappedComponent = Component;
  return (props) => {
    const { user, isAuthenticated, loading } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    useEffect(() => {
      if (isAuthenticated) {
        return;
      }
      const token = localStorage.getItem("access_token");
      switch (type) {
        case "HARD": {
          if (!token) {
            dispatch(logout());
            navigate("/login");
            return;
          }
          dispatch(authenticate(navigate));
          break;
        }
        case "SOFT": {
          if (token) {
            dispatch(authenticate(navigate));
          }
          break;
        }
        default:
          dispatch(logout());
          navigate("/login");
          return;
      }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
      const interval = setInterval(() => {
        const refresh_token = localStorage.getItem("refresh_token");
        if (!refresh_token) {
          return;
        }
        refreshAccessToken(refresh_token)
          .then((access_token) => {})
          .catch((err) => {
            logout();
          });
      }, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    if (!user) {
      return null;
    }

    if (type === "HARD" && !token) {
      return null;
    }

    if (!isAuthenticated && type !== "SOFT") {
      return <BackdropLoader open={true} />;
    }
    return (
      <>
        <BackdropLoader open={loading} />
        <WrappedComponent {...props} user={user} />
      </>
    );
  };
}

export default withAuth;
