import {
  Dashboard,
  Login,
  PhotoAlbum,
  Message,
  Person,
  PersonAdd,
} from "@mui/icons-material";
import ValidationService from "easy-validation-service";

export const NavigationData = [
  {
    title: "Dashboard",
    to: "/",
    icon: <Dashboard />,
    shouldRender: (role, isAuthenticated) => isAuthenticated,
  },
  {
    title: "Gallery",
    to: "/images",
    icon: <PhotoAlbum />,
    shouldRender: (role, isAuthenticated) => isAuthenticated,
  },
  {
    title: "Messages",
    to: "/messages",
    icon: <Message />,
    shouldRender: (role, isAuthenticated) => isAuthenticated,
  },
  {
    title: "Login",
    to: "/login",
    icon: <Login />,
    shouldRender: (role, isAuthenticated) =>
      ValidationService.isNullOrUndefinedOrEmpty(
        localStorage.getItem("access_token")
      ) && !isAuthenticated,
  },
  {
    title: "Register",
    to: "/register",
    icon: <PersonAdd />,
    shouldRender: (role, isAuthenticated) =>
      ValidationService.isNullOrUndefinedOrEmpty(
        localStorage.getItem("access_token")
      ) && !isAuthenticated,
  },
];
