import React, { useCallback, useState } from "react";
import { useFetchData } from "../../store/hooks/useFetch";
import { toApiUrl, uploadFile } from "../../utils/generic";
import { addNotification } from "../../store/actions/notifications";
import axios from "../../apiClient";
import Images from "./Images";
import { Grid, IconButton, Tooltip } from "@mui/material";
import FileImport from "../forms/FileImport";
import { AddAPhotoRounded, Delete, Edit } from "@mui/icons-material";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import Paginator from "../dataGrid/Paginator";
import SelectInput from "../forms/SelectInput";

export default function ImageTabContainer({
  category,
  onDeleteCategory,
  onEdit,
}) {
  const [orderBy, setOrderBy] = useState("created_desc");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dataFormatter = useCallback((prevState, newData) => {
    return newData;
  }, []);
  const { data, loading, refetch } = useFetchData(
    toApiUrl(`/images?category_id=${category?.id}`),
    dataFormatter
  );

  const onUploadFile = async (files) => {
    setIsLoading(true);
    try {
      if (!files?.length) {
        throw new Error();
      }
      if (files.length > 10) {
        addNotification("Maximum number of upload files is 10", "error");
        throw new Error();
      }
      const promises = [...files].map((file) =>
        uploadFile(file, { category_id: category?.id })
      );
      await Promise.all(promises);
      addNotification("Files Uploaded", "success");

      refetch(data?.page, data?.view || 10, { orderBy });
    } catch (error) {
      addNotification("Files were not Uploaded", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteFile = async (file) => {
    try {
      setIsLoading(true);
      await axios.delete(toApiUrl(`/images/${file.id}`));
      refetch(data?.page, data?.view, { orderBy });
      addNotification("Image Deleted", "success");
    } catch (error) {
      addNotification("Image was not deleted", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      setIsLoading(true);
      await axios.delete(toApiUrl(`/categories/${category.id}`));
      addNotification("Category deleted", "success");
      onDeleteCategory();
    } catch (error) {
      addNotification("Category was not deleted", "error");
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <IconButton
          onClick={() => {
            onEdit(category);
          }}
        >
          <Tooltip title="Edit Category">
            <Edit />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <FileImport
          disabled={isLoading}
          multiple
          accept={"image/*"}
          icon={
            <Tooltip title="Add Image">
              <AddAPhotoRounded />{" "}
            </Tooltip>
          }
          onFileUpload={onUploadFile}
        />
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <Tooltip title="Delete Category">
            <Delete />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item>
        <Paginator
          page={(data?.page || 1) - 1}
          view={data?.view || 10}
          total={data?.total || 0}
          allowedViews={[10, 25, 50, 100]}
          onPageChange={(_, page) => {
            refetch(page + 1, data?.view, { orderBy });
          }}
          onViewChange={async (view) => {
            refetch(data?.page, view, { orderBy });
          }}
        />
      </Grid>
      <Grid item>
        <SelectInput
          size="small"
          isValid
          isTouched
          value={orderBy}
          options={[
            { label: "Most Recent", value: "created_desc" },
            { label: "Oldest First", value: "created_asc" },
            { label: "Name Ascending", value: "name_asc" },
            { label: "Name Descending", value: "name_desc" },
          ]}
          disabled={loading}
          label="Sort by"
          onChange={(e) => {
            setOrderBy(e.target.value);
            refetch(data?.page, data?.view, { orderBy: e.target.value });
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Images
          data={data}
          loading={loading || isLoading}
          onDeleteFile={onDeleteFile}
        />
        <Paginator
          page={(data?.page || 1) - 1}
          view={data?.view || 10}
          total={data?.total || 0}
          allowedViews={[10, 25, 50, 100]}
          onPageChange={(_, page) => {
            refetch(page + 1, data?.view, { orderBy });
          }}
          onViewChange={async (view) => {
            refetch(data?.page, view, { orderBy });
          }}
        />
        {/* </InfiniteScroll> */}
      </Grid>
      <ConfirmationDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={async () => {
          await handleDeleteCategory();
        }}
      />
    </Grid>
  );
}
