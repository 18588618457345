import { UploadFile } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useRef } from "react";

export default function FileImport({
  icon,
  name,
  accept,
  onFileUpload,
  multiple,
  disabled,
  label,
}) {
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const files = multiple ? e.target.files : e.target.files?.[0];
    onFileUpload(files);
    fileInputRef.current.value = null;
  };

  return (
    <>
      {label ? label : null}
      <input
        name={name}
        accept={accept}
        multiple
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <IconButton
        disabled={disabled}
        onClick={() => fileInputRef.current.click()}
      >
        {icon || <UploadFile />}
      </IconButton>
    </>
  );
}
