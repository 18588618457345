import { Edit, Save } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../store/actions/auth";
import Input from "../forms/Input";
import AnalyticsCard from "../ui/AnalyticsCard";
import useProfileForm from "./useProfileForm";
import BackdropLoader from "../ui/BackdropLoader";
import useAuth from "../../store/hooks/useAuth";

function ProfileForm({ updateProfile }) {
  const { loading, user } = useAuth();

  const { form, handleChange, isValid, isTouched, getFormValues } =
    useProfileForm(user);

  if (!user) {
    return null;
  }

  return (
    <>
      <BackdropLoader open={loading} />
      <AnalyticsCard
        title="Profile"
        divider
        icon={<Edit />}
        sx={{ height: "100%" }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Input
              {...form.email}
              name="email"
              label="Email"
              onChange={handleChange}
              value={form.email.value}
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.name}
              name="name"
              label="Name"
              onChange={handleChange}
              value={form.name.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              {...form.last_name}
              name="last_name"
              label="Last Name"
              onChange={handleChange}
              value={form.last_name.value}
            />
          </Grid>

          <Grid item md={12} xs={12} display="flex" justifyContent="center">
            <Button
              disabled={!isValid || !isTouched || loading}
              variant="contained"
              startIcon={<Save />}
              onClick={async () => {
                await updateProfile(getFormValues());
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </AnalyticsCard>
    </>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
    loading: auth.loading,
  }),
  { updateProfile }
)(ProfileForm);
