import React from "react";
import { Grid } from "@mui/material";
import BackdropLoader from "../ui/BackdropLoader";
import Message from "../ui/Message";
import ImageItem from "./ImageItem";

export default function Images({ data, loading, onDeleteFile }) {
  return (
    <Grid container spacing={2}>
      <BackdropLoader open={loading} />
      {!loading && !data?.results?.length ? (
        <Grid item md={12} xs={12}>
          <Message message="No data at the moment" />{" "}
        </Grid>
      ) : null}
      {(data?.results || []).map((file) => (
        <Grid item md={6} lg={6} xs={12} key={file.id}>
          <ImageItem image={file} onDeleteFile={onDeleteFile} />
        </Grid>
      ))}
    </Grid>
  );
}
