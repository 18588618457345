export const DataActions = {
  DATA_LOADING: "DATA_LOADING",
  FETCH_DATA: "FETCH_DATA",
  INIT_SLICE: "INIT_SLICE",
  REMOVE_SLICE: "REMOVE_SLICE",
  SET_FILTERS: "SET_FILTERS",
};

export const dataReducer = (state = {}, action) => {
  const stateSlice = state?.[action.identifier] || {};

  switch (action.type) {
    case DataActions.INIT_SLICE: {
      return {
        ...state,
        [action.identifier]: {
          isInitialized: true,
          page: 1,
          view: 10,
          total: 0,
          data: [],
          loading: false,
          columns: action.columns || [],
          searchFilters: {},
          ...action.payload,
        },
      };
    }
    case DataActions.SET_FILTERS:
      return {
        ...state,
        [action.identifier]: {
          ...stateSlice,
          searchFilters: action.payload,
        },
      };
    case DataActions.DATA_LOADING:
      return {
        ...state,
        [action.identifier]: {
          ...stateSlice,
          loading: action.payload,
        },
      };
    case DataActions.FETCH_DATA:
      return {
        ...state,
        [action.identifier]: {
          ...stateSlice,
          ...action.payload,
        },
      };
    case DataActions.REMOVE_SLICE:
      const { [action.payload]: removedSlice, ...rest } = state;
      return {
        ...rest,
      };
    default:
      return state;
  }
};
