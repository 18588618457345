import { useCallback, useEffect, useState } from "react";
import axios from "../../apiClient";

export const useFetchData = (url, dataFormatter = null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const refetch = useCallback(
    (page = 1, view = 10, filters = {}) => {
      setLoading(true);
      axios
        .get(url, { params: { page, view, ...filters } })
        .then((res) =>
          setData((prev) =>
            dataFormatter ? dataFormatter(prev, res.data) : res.data
          )
        )
        .catch((err) => setLoading(false))
        .finally(() => setLoading(false));
    },
    [dataFormatter, url]
  );
  useEffect(() => {
    refetch();
  }, [refetch]);

  return { loading, data, refetch, setData };
};
